import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { containerMaxWidth } from "../constants"
import Layout from "../components/Layout"

const Container = styled.div`
  max-width: ${containerMaxWidth};
  margin: 14.2rem auto;
  padding: 0 3rem;
`

const Title = styled.h1`
  padding: 6rem 3rem 1rem;
  text-align: center;
`

const Text = styled.p`
  padding: 0 6rem;
  text-align: center;
`

const OutOfStockPage = ({ pageContext }) => {
  const { t } = useTranslation("translations")

  return (
    <Layout
      meta={{ title: "Product is out of stock!" }}
      invertedHeader
      pageContext={pageContext}
    >
      <Container>
        <Title>{t("Product is out of stock!")}</Title>
        <Text>{t("We are working on getting the product back in stock.")}</Text>
      </Container>
    </Layout>
  )
}

export default OutOfStockPage
